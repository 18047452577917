import ng from 'angular';

export function acpStopTouchEventDirective(): ng.IDirective {
  'ngInject';
  return {
    restrict: 'A',
    link: (scope, element: JQLite) => {
      element.on('touchmove', (evt) => {
        evt.stopPropagation();
      });
    }
  };
}

const ngModule = ng
  .module('acp.shared.directives.stopTouchEventDirective', [])
  .directive('acpStopTouchEvent', acpStopTouchEventDirective);

export default ngModule;
